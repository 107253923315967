import {
	CloseCircleFilled, CheckCircleFilled, QuestionCircleFilled, MinusCircleFilled
} from '@ant-design/icons';
import { Role } from '../../features/user/Permission';

export const KitType = {
	UNIVERSAL_KIT: "U",
	KIT: "K",
	Y_CABLE_KIT: "Y",
	ADDITIONAL: "A"
}

export const SupportedFlag = {
	UNKNOWN: 1,
	SUPPORTED: 2,
	NOT_SUPPORTED: 3
}

export const VehicleModelFormItem = (t) => [
	{
		name: 'make',
		label: t('VehicleModel.Make'),
		rules: [
			{
				required: true,
				message: t('ValidationErrors.MakeRequired')
			},
			{
				pattern: new RegExp(/^[^!@#$%^&*()_+=\-[\]{}|\\:;"'<>,.?/~` ]/),
				message: t('ValidationErrors.InvalidMakeFormat')
			}
		]
	},
	{
		name: 'model',
		label: t('VehicleModel.Model'),
		rules: [
			{
				required: true,
				message: t('ValidationErrors.ModelRequired')
			},
			{
				pattern: new RegExp(/^[^!@#$%^&*()_+=\-[\]{}|\\:;"'<>,.?/~` ]/),
				message: t('ValidationErrors.InvalidModelFormat')
			}
		]
	},
	{
		name: 'yearOfManufacture',
		label: t('VehicleModel.Year'),
		rules: [
			{
				required: true,
				message: t('ValidationErrors.YearRequired')
			},
			{
				pattern: new RegExp(/^[1-9]\d*$/),
				message: t('ValidationErrors.InvalidManufactureYearFormat')
			}
		]
	}
];

export const PATHS = {
	VEHICLE_COMPATIBILITY_DEFAULT: '/vehicleCompatibilitySearch',
	VEHICLE_COMPATIBILITY: '/vehicleCompatibility',
	VEHICLE_MODEL: '/vehicleModel',
	VEHICLE_MODEL_GROUP: '/vehicleModelGroup'
};

export const VERIFICATION_STATUS = {
	"V":{
		label:"VehicleModel.Verified",
		icon : <CheckCircleFilled style={{ color: "green" }} />,
		nextStatus:"N"
	},
	"U":{
		label:"VehicleModel.NotVerified",
		icon : <MinusCircleFilled style={{ color: "darkgrey" }} />,
		nextStatus:"P"
	},
	"P":{
		label:"VehicleModel.PossibleVerified",
		icon : <QuestionCircleFilled style={{ color: "orange" }} />,
		nextStatus:"V",
		role : [Role.ADMIN, Role.READONLY_ADMIN, Role.SITE_ADMIN]
	},
	"N":{
		label:"VehicleModel.Unsupported",
		icon : <CloseCircleFilled style={{ color: "palevioletred" }} />,
		nextStatus:"U",
		role : [Role.ADMIN, Role.READONLY_ADMIN, Role.SITE_ADMIN]
	},
}