import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { Panel, PanelBody } from './../../components/panel/panel.js';
import { Table, InputNumber, Space, Spin, Form, Button, Row, Col, Input, Select, AutoComplete, Typography, Tooltip } from 'antd';
import style from './VehicleCompatibility.module.scss';
import { useDispatch } from 'react-redux';
import { setBackButton, setPageTitle } from '../../features/header/headerSlice';
import { useVehicleModelWithSource, useGetVehicleModelsQuery, useSaveVehicleModelBulkMutation, useDeleteVehicleModelMutation, usePolls, useVoteForScanRequestMutation, useUnVoteForScanRequestMutation, useVehicleCompatibility, useUpdateVehicleModelMeasurementMutation } from '../../features/vehicleModels/vehicleModelApi.js';
import { RouteKey } from '../../config/page-key.js';

import formStyles from './VehicleMakeModelYearFormModal.module.scss';
import { useCurrentCompany } from '../../features/company/companySlice.js';

import { useFuzzySearch } from '../../utils/fuzzySearch.js';
import { SupportedFlag, VehicleModelFormItem, VERIFICATION_STATUS } from './constant.js';
import { getVehicleModelDataColumnsForUpdate } from './VehicleModelHelper.js';
import { useCan } from '../../features/user/Can.js';
import { Permission } from '../../features/user/Permission.js';
import { confirmationModal } from '../../features/confirmationModal/confirmationModal.js';
import { ResponsiveButton } from '../../features/responsive/ResponsiveButton.js';

import {
	ScanOutlined, UploadOutlined
} from '@ant-design/icons';
import { addToast } from '../../features/toast/toastsSlice.js';
import { executeBulkAction, executeSingleAction } from '../../utils/ApiResponseHandler.js';
import { useDeleteDocumentMutation, useGetDocument, useSaveDocumentMutation } from '../../features/document/documentApi.js';
import { ENTITY_TYPE_VEHICLE_MODEL } from '../document/constant.js';
import { parseErrorMessage } from '../../utils/strings.js';
import VehicleModelDocumentUploadModal from './VehicleModelDocumentUploadModal.js';
import VehicleDocumentList from './VehicleDocumentList.js';

const { Option } = Select;
const { TextArea } = Input;
const { Title, Text } = Typography;

const VehicleModelGroupForm = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { i18n, t } = useTranslation();
	const currentCompany = useCurrentCompany();
	const { data: fullVehicleModel, isFetching } = useGetVehicleModelsQuery({ companyId: currentCompany?.id }, { skip: currentCompany?.id === undefined });
	const [isDirty, setIsDirty] = useState(false);
	const [hasDbcOption, setHasDbcOption] = useState(false);
	const [dbcOption, setDbcOption] = useState([]);
	const [saveVehicleModelBulk] = useSaveVehicleModelBulkMutation();
	const [form] = Form.useForm();
	const { vehicleModels: makeModelYearList } = useVehicleModelWithSource(currentCompany?.id)
	const { performIndividualKeySearch } = useFuzzySearch(makeModelYearList || [], ['make', 'model', 'yearOfManufacture']);
	const [selectedVehicleModel, setSelectedVehicleModel] = useState(undefined);
	const [vehicleModels, setVehicleModels] = useState([]);
	const [deleteVehicleModel] = useDeleteVehicleModelMutation();
	const can = useCan();
	const { polls, history: userVoteHistory, isFetching: isFetchingPoll } = usePolls();
	const [voteForScanRequest] = useVoteForScanRequestMutation();
	const [unVoteForScanRequest] = useUnVoteForScanRequestMutation();
	const [votes, setVotes] = useState(new Set());
	const [unvotablePolls, setUnvotablePolls] = useState(new Map());
	const [finalDocumentList, setFinalDocumentList] = useState([]);
	const [isDocumentUploadModalVisible, setIsDocumentUploadModalVisible] = useState(false);
	const [deleteDocument] = useDeleteDocumentMutation();
	const [saveDocument] = useSaveDocumentMutation();
	const [documentList, setDocumentList] = useState([]);
	const { vehicleCompatibility } = useVehicleCompatibility(currentCompany.id);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [measurementList, setMeasurementList] = useState([]);
	const [updateVehicleModelMeasurement] = useUpdateVehicleModelMeasurementMutation();

	const { data: documents, isFetching: isFetchingDocument } = useGetDocument(currentCompany.id);
	const documentMap = useMemo(() => {
		return Object.fromEntries((documents || []).filter(i => (i.data || []).length > 0).map(item => [item.attachmentId, item]));
	}, [documents, isFetchingDocument]);

	useEffect(() => {
		if (!isFetchingPoll) {
			const latestVotes = new Set();
			userVoteHistory.forEach(obj => {
				const key = obj['vehicleModelId'];
				latestVotes.add(key);
			});
			setVotes(latestVotes);
		}
	}, [isFetchingPoll, userVoteHistory]);

	useEffect(() => {
		if (!isFetchingPoll) {
			const latestPolls = new Map();
			polls.filter(poll => poll.status !== 'IN_REVIEW').forEach(poll => {
				latestPolls.set(poll.vehicleModel.id, poll);
			});
			setUnvotablePolls(latestPolls);
		}
	}, [isFetchingPoll, polls]);

	useEffect(() => {
		if ((history.location?.state?.record || []).length > 0 && documentMap && vehicleCompatibility) {
			//they are the same since groupped, just take the first one
			const model = vehicleCompatibility.find(i=>i.id === history.location?.state?.record[0]);
			setDocumentList(model.attachments.map(i => {
				return documentMap[i.attachmentId];
			}).filter(i => !!i));

			const measurements = (model?.supportedPid || []);
			const uniqueMeasurements = Object.values(
				(measurements || []).reduce((acc, item) => {
				  const key = `${item.canId}-${item.id || ""}`;
			  
				  if (!acc[key]) {
					acc[key] = { key, items: [] };
				  }
			  
				  // Ensure uniqueness based on `pid`
				  if (!acc[key].items.some((existingItem) => existingItem.pid === item.pid)) {
					acc[key].items.push({...item, originalVerified : item.verificationStatus === "V"});
				  }
			  
				  return acc;
				}, {})
			  ).flatMap(group => group.items);
			setMeasurementList(uniqueMeasurements);
		}
	}, [history.location.state.record, documentMap, vehicleCompatibility]);

	useEffect(() => {
		if (documentList && !isSubmitting) {
			setFinalDocumentList(documentList);
		}
	}, [documentList, isSubmitting]);

	const [localTempSearchedValue, setLocalTempSearchedValue] = useState({
		make: "",
		model: "",
		yearOfManufacture: ""
	});

	const formItems = VehicleModelFormItem(t);

	useEffect(() => {
		if (!isFetching) {
			const models = JSON.parse(JSON.stringify([...(fullVehicleModel || []).filter(i => (history.location?.state?.record || []).includes(i.id))]));
			//take the first one will do, they are all having the same detail except year
			if (models) {
				models.forEach(m => {
					if (!!m?.groupId) {
						const pairedInventure = fullVehicleModel.find(i => i.source == "inventure" && i.groupId == m.groupId);
						m.canEdit = !pairedInventure;
					}
					else {
						m.canEdit = m?.source !== "inventure";
					}

					m.canEdit &= can({ everyPermission: Permission.COMMON_EDIT });
					m.canDelete = m.canEdit && m?.company?.id !== undefined;
				})
				setVehicleModels(models);

				if (models.length !== 0) {
					const modelRef = models[0];
					dispatch(setPageTitle(modelRef.make + " " + modelRef.model));
					dispatch(setBackButton(true));

					return () => {
						dispatch(setPageTitle(""));
						dispatch(setBackButton(false));
					}
				}
			}
		}
	}, [history.location?.state, t, fullVehicleModel, isFetching, documentMap]);

	const onFinish = (e) => {
		setIsSubmitting(true);
		let payload = vehicleModels.map(i => {
			let parsedPayload = {
				...i,
				make: i.make,
				model: i.model,
				yearOfManufacture: i.yearOfManufacture,
				priority: e.priority,
				dbcFileKey: e.dbc
			};

			if (e.isSupported === SupportedFlag.NOT_SUPPORTED) {
				parsedPayload.isSupported = false;
			}
			else if (e.isSupported === SupportedFlag.SUPPORTED) {
				parsedPayload.isSupported = true;
			}

			if (i.extras) {
				const oldExtrasObj = JSON.parse(i.extras);
				const newExtrasObj = { ...oldExtrasObj };
				newExtrasObj.note = e.note;

				if (oldExtrasObj.dbcAvailable) {
					const selectedDbc = oldExtrasObj.dbcAvailable.find(i => i.dbc_file_key === e.dbc);

					newExtrasObj.baudrate = selectedDbc.baudrate;
					newExtrasObj.dbcAvailable.forEach(i => {
						delete i["is_default"];
					})
				}
				parsedPayload.extras = JSON.stringify(newExtrasObj);
			}
			else {
				parsedPayload.extras = JSON.stringify({ note: e.note });
			}

			return parsedPayload;
		});

		payload.identifier = "VehicleModel" + payload.map(i => i.id).join(",");
		payload.loadingMessage = t("Common.Saving");
		payload.successMessage = t("VehicleModel.VehicleModelSaved");
		payload.partialMessage = "VehicleModel.VehicleModelSavedPartially";

		let promiseArray = [];
		let promiseDelay = 10;
		let newAttachments = finalDocumentList.filter(i => !i.id);

		if (newAttachments.length > 0) {
			const addPayload = {
				ids: vehicleModels.map(i=>i.id),
				entityType: ENTITY_TYPE_VEHICLE_MODEL
			}

			addPayload["file"] = finalDocumentList.filter(i => !i.id).filter(i => i.file instanceof File);
			addPayload["link"] = finalDocumentList.filter(i => !i.id).filter(i => !!i.url);

			promiseArray.push(
				new Promise(resolve =>
					setTimeout(
						() => resolve(saveDocument(addPayload)),
						promiseDelay
					)
				)
			);
		}

		promiseDelay += 10;

		documentList.forEach(d => {
			if (!finalDocumentList.find(i => i.id === d.id)) {
				const deletePayload = {
					attachmentId: d.attachmentId,
					ids: vehicleModels.map(i=>i.id),
					entityType: ENTITY_TYPE_VEHICLE_MODEL
				}

				promiseArray.push(
					new Promise(resolve =>
						setTimeout(
							() => resolve(deleteDocument(deletePayload)),
							promiseDelay
						)
					)
				);
				promiseDelay += 10;
			}
		})

		const measurementListToUpdate = measurementList.filter(i=>i.verificationStatus !== "N");
		if (measurementListToUpdate.length > 0) {
			const measurementPayload = {
				pids: measurementListToUpdate.reduce((acc, item) => {
					acc[item.id] = item.verificationStatus;
					return acc;
				  }, {}),
				vehicleModelIds: vehicleModels.map(i=>i.id)
			}

			promiseArray.push(
				new Promise(resolve =>
					setTimeout(
						() => resolve(updateVehicleModelMeasurement(measurementPayload)),
						promiseDelay
					)
				)
			);
		}

		Promise.all(promiseArray)
			.then(async () => {
				executeBulkAction(payload, saveVehicleModelBulk, dispatch).then(() => {
					setIsSubmitting(false);
					if (history.length > 2) {
						history.goBack();
					}
					else {
						history.push(RouteKey.VEHICLE_COMPATIBILITY);
					}
				});
			})
			.catch(err => {
				dispatch(addToast({
					key: Date.now(),
					type: "error",
					content: parseErrorMessage(err.data),
				}));
				setIsSubmitting(false);
			})
	};

	useEffect(() => {
		const data = vehicleModels?.[0] || null;
		let formData = {
			make: data?.make,
			mode: data?.model,
			yearOfManufacture: data?.yearOfManufacture,
			priority: data?.priority,
			isSupported: data?.isSupported !== undefined ? (data.isSupported ? SupportedFlag.SUPPORTED : SupportedFlag.NOT_SUPPORTED) : SupportedFlag.UNKNOWN,
			source: data?.displaySource !== undefined ? data?.displaySource : data?.source,
		};

		if (data?.extras) {
			const extra = JSON.parse(data.extras);
			formData["note"] = extra.note;
		}

		if (data?.dbcFileKey) {
			let dbcOptionAvailable = [];
			dbcOptionAvailable.push({ label: data?.dbcFileKey, value: data?.dbcFileKey });
			if (data?.extras) {
				const vmExtras = JSON.parse(data.extras);
				if (vmExtras.dbcAvailable) {
					dbcOptionAvailable = [];
					vmExtras.dbcAvailable.forEach(dbcOpt => {
						dbcOptionAvailable.push({ label: dbcOpt?.dbc_file_key, value: dbcOpt?.dbc_file_key });
					})
					setHasDbcOption(true);
				}
			}
			setDbcOption(dbcOptionAvailable)
			formData["dbc"] = data?.dbcFileKey;
		}

		form.setFieldsValue({ ...data, ...formData });
	}, [form, vehicleModels]);

	const renderFormItems = formItems => {
		return <Row className={style.fullWidthRow}>
			{
				formItems.map((formItem, formItemIndex) => {
					const { name, label, rules } = formItem;
					return (
						<Col xs={{ span: 24 }}>
							<Form.Item
								key={`${formItemIndex}-${name}`}
								name={name}
								labelAlign="left"
								className={style.inputLeft}
								label={label}
								colon={false}
								rules={rules}
							>
								{renderFormItemChild(name)}
							</Form.Item>
						</Col>
					);
				})
			}
		</Row>
	};

	const renderNotFoundPanel = type => {
		let possibleMatch = [];
		if (localTempSearchedValue.value) {
			const possibleMatchModel = performIndividualKeySearch(localTempSearchedValue.value || '', type);
			possibleMatch = Array.from(new Set(possibleMatchModel.map(i => i[type]))).slice(0, 5);
		}

		if ((possibleMatch || []).length !== 0) {
			return (
				<div style={{ padding: "0px 10px" }}>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						{(possibleMatch || []).map((data, index) => (
							<a
								key={index}
								className={formStyles.makeModelYearSuggestionValue}
								onClick={() => {
									form.setFieldValue(type, data);
									document.activeElement.blur();
								}}
							>
								{data}
							</a>
						))}
					</div>
				</div>
			);
		}
	};

	const handleValueSearch = (value, type) => {
		value && setLocalTempSearchedValue({ value, type });
	};

	const renderFormItemChild = (name) => {
		const options = Array.from(new Set((makeModelYearList || []).map(i => i[name])))
			.filter(i => i?.toString() !== undefined && i?.toString()?.length !== 0)
			.map(data => {
				return { label: data?.toString(), value: data?.toString() };
			});


		return (
			<AutoComplete
				onSelect={e => {
					form.setFieldValue(name, e);
				}}
				size={"large"}
				options={options}
				filterOption={(inputValue, option) =>
					option.value
						?.toString()
						.toUpperCase()
						.indexOf(inputValue?.toString().toUpperCase()) !== -1
				}
				onFocus={() => {
					handleValueSearch(form.getFieldValue(name), name);
				}}
				onSearch={e => {
					handleValueSearch(e, name);
				}}
				notFoundContent={renderNotFoundPanel(name)}
			/>
		);
	};

	const onEdit = (v) => {

		confirmationModal(
			t('VehicleModel.EditVehicleModelGroupTitle', { vehicleModel: v?.make + " " + v?.model + " " + v?.yearOfManufacture }),
			t('VehicleModel.EditVehicleModelGroupDescription'),
			t('Common.ConfirmButton'),
			t('Common.CancelButton'),
			() => {
				const model = vehicleCompatibility.find(i=>i.id === v.id);
				history.replace(RouteKey.VEHICLE_MODEL, { record: model });
			}
		);
	}

	const onDelete = (v) => {
		confirmationModal(
			t('VehicleModel.DeleteVehicleModelTitle', { vehicleModel: v?.make + " " + v?.model + " " + v?.yearOfManufacture }),
			t('VehicleModel.DeleteVehicleModelDescription', { vehicleModel: v?.make + " " + v?.model + " " + v?.yearOfManufacture }),
			t('Common.Delete'),
			t('Common.CancelButton'),
			() => {
				const payload = {
					successMessage: i18n.t("VehicleModel.VehicleModelDeleted"),
					id: v.id,
					identifier: "VehicleModel" + v.id,
					loadingMessage: t("Common.Saving")
				}
				executeSingleAction(payload, deleteVehicleModel, dispatch).then(() => {
					const vehicleModelLeft = vehicleModels.filter(i => i.id !== v.id);
					if (vehicleModelLeft.length === 0) {
						if (history.length > 2) {
							history.goBack();
						}
						else {
							history.push(RouteKey.VEHICLE_COMPATIBILITY);
						}
					}
					else {
						setVehicleModels([...vehicleModelLeft]);
					}
				})
			},
			'delete'
		);
	}

	const scanRequestRender = (record) => {
		if (record?.source === 'internal' && !record?.isDummyInternal) {
			if (unvotablePolls.has(record.id)) {
				return <Tooltip title={unvotablePolls.get(record.id).comment}>
					<ResponsiveButton type="text" size={"small"} style={{ color: '#A0A0A0', cursor: 'not-allowed' }}
						title={t("VehicleModel.PollClosed", { status: t('VehicleModel.RequestStatus.' + unvotablePolls.get(record.id).status) })}
						icon={<ScanOutlined />} />
				</Tooltip>
			} else if (votes.has(record.id)) {
				return <ResponsiveButton type="text" size={"small"} onClick={() => {
					unVoteForScanRequest({ id: record.id });
					votes.delete(record.id);
					dispatch(
						addToast({
							title: i18n.t("VehicleModel.ScanRequest"),
							type: "success",
							content: <Trans
								i18nKey={'VehicleModel.RequestCancelled'}
								components={{ primary: <Text type="success" strong />, secondary: <Text strong /> }}
							/>,
						})
					);

				}} title={t("VehicleModel.CancelRequest")} icon={<ScanOutlined />} />
			} else {
				return <ResponsiveButton type="text" size={"small"} onClick={() => {
					voteForScanRequest({ id: record.id });
					votes.add(record.id);
					dispatch(
						addToast({
							title: i18n.t("VehicleModel.ScanRequest"),
							type: "success",
							content: <Trans
								i18nKey={'VehicleModel.ScanRequested'}
								components={{ primary: <Text type="success" strong />, secondary: <Text strong /> }}
							/>,
						})
					);

				}} title={t("VehicleModel.RequestScan")} icon={<ScanOutlined />} />
			}
		}
	}

	return (
		<>
			<Spin spinning={isFetching || isSubmitting}>
				<Form form={form} layout="vertical"  style={{ marginBottom: "60px" }} onFinish={(e) => {
					if (!!selectedVehicleModel) {
						let edittingModel = vehicleModels.find(i => i.id === selectedVehicleModel.id);
						setVehicleModels([...vehicleModels.filter(i => i.id !== selectedVehicleModel.id), ...[{ ...edittingModel, ...e }]]);
						setSelectedVehicleModel(undefined);
					}
					else {
						onFinish(e);
					}
				}} onValuesChange={() => { setIsDirty(true) }}>
					<Row style={{ margin: 16 }}>
						<Col xs={{ span: 24 }} lg={{ span: 16 }}>
							<Panel style={{ marginBottom: "15px", background: "#fdfdfd" }}>
								<Title type="secondary" level={4} className={style.titleText}>{t("VehicleModel.Setting")}</Title>
								<PanelBody>
									<Row className={style.fullWidthRow}>
										<Col xs={{ span: 24 }} sm={{ span: 12 }}>
											<Row className={style.fullWidthRow}>
												<Col xs={{ span: 24 }}>
													<Form.Item
														name="isSupported"
														label={t("VehicleModel.Supported")}
														className={style.inputLeft}
													>
														<Select size={"large"}>
															<Option value={SupportedFlag.UNKNOWN}>{t("VehicleModel.Unknown")}</Option>
															<Option value={SupportedFlag.SUPPORTED}>{t("VehicleModel.Yes")}</Option>
															<Option value={SupportedFlag.NOT_SUPPORTED}>{t("VehicleModel.No")}</Option>
														</Select>
													</Form.Item>
												</Col>
												<Col xs={{ span: 24 }}>
													<Form.Item
														label={t("VehicleModel.Source")}
														name="source"
														className={style.inputLeft}
													>
														<Input disabled={true} size={"large"} />
													</Form.Item>
												</Col>
												<Col xs={{ span: 24 }}>
													<Form.Item
														name="dbc"
														label={t("VehicleModel.DbcKey")}
														className={style.inputLeft}>
														{
															dbcOption.length !== 0 && hasDbcOption ?
																<Select size={"large"} options={dbcOption} disabled={dbcOption.length <= 1} /> :
																<Input size={"large"} disabled={!vehicleModels[0]?.canEdit} />
														}
													</Form.Item>
												</Col>
												<Col xs={{ span: 24 }}>
													<Form.Item
														label={t("VehicleModel.Priority")}
														name="priority"
														className={style.inputLeft}
														rules={[{ required: true, message: t("ValidationErrors.PriorityRequired") }]}
													>
														<InputNumber style={{ width: "100%" }} size={"large"} />
													</Form.Item>
												</Col>
											</Row>
										</Col>
										<Col xs={{ span: 24 }} sm={{ span: 12 }}>
											<Form.Item
												label={t("VehicleModel.Note")}
												name="note"
												className={style.inputLeft}
											>
												<TextArea rows={8} />
											</Form.Item>
										</Col>
									</Row>
								</PanelBody>
							</Panel>
							{selectedVehicleModel &&
								<Panel style={{ marginBottom: "15px", background: "#fdfdfd" }}>
									<Title type="secondary" level={4} className={style.titleText}>{t("VehicleModel.ExportFileName")}</Title>
									<PanelBody>
										<Row className={style.fullWidthRow}>
											<Col xs={{ span: 24 }} md={{ span: 12 }}>{renderFormItems(formItems)}</Col>
										</Row>
									</PanelBody>
								</Panel>
							}
							{!selectedVehicleModel &&
								<Panel style={{ marginBottom: "15px", background: "#fdfdfd" }}>
									<Title type="secondary" level={4} className={style.titleText}>{t("VehicleModel.VehicleModelGroup")}</Title>
									<PanelBody>
										<Row className={style.fullWidthRow}>
											<Col xs={{ span: 24 }}>
												<Table size='small'
													pagination={false}
													columns={getVehicleModelDataColumnsForUpdate(onEdit, onDelete, scanRequestRender)}
													dataSource={vehicleModels} />
											</Col>
										</Row>
									</PanelBody>
								</Panel>
							}
							<Panel style={{ marginBottom: "15px", background: "#fdfdfd" }}
								header={
									<div style={{ display: "flex", justifyContent: "space-between" }}>
										<Title type="secondary" level={4}>{t("VehicleModel.GuideAndDocument")}</Title>
										<Text className={style.documentPanelHeaderContainer}>
											<div className={style.documentPanelCountTitle} style={{ background: "#f7f7f7", borderRadius: 10 }}>
												{t("VehicleModel.UploadedDocumentCount", { number: (finalDocumentList || []).length, total: 10 })}
											</div>
										</Text>
										<div className={style.documentPanelHeaderContainer}><ResponsiveButton onClick={() => {
											setIsDocumentUploadModalVisible(true);
										}}
											title={t("VehicleModel.UploadDocs")}
											icon={<UploadOutlined />} />
										</div>
									</div>
							}>
								<PanelBody>
									{finalDocumentList.length !== 0 &&
										<VehicleDocumentList documents={finalDocumentList} onRemoved={(index) => {
											setFinalDocumentList(finalDocumentList.filter((_, i) => i !== index));
											setIsDirty(true)
										}} />
									}
								</PanelBody>
							</Panel>
							{measurementList.length !== 0 && <Panel style={{ marginBottom: "15px", background: "#fdfdfd" }}
								header={
									<div style={{ display: "flex", justifyContent: "space-between" }}>
										<Title type="secondary" level={4}>{t("VehicleModel.VerifyMeasurement")}</Title>
										<Text className={style.documentPanelHeaderContainer}>
											<div className={style.documentPanelCountTitle} style={{ background: "#f7f7f7", borderRadius: 10 }}>
												{t("VehicleModel.VerifiedMeasurement", { number: (measurementList || []).filter(i => i.verificationStatus === "V").length, total: measurementList.length })}
											</div>
										</Text>
									</div>
								}>
								<PanelBody>
									<Space wrap>
										{
											(measurementList.map(pid => {
												return (
													<Tooltip title={t(VERIFICATION_STATUS[pid.verificationStatus].label)}>
														<Button disabled={pid.originalVerified} shape="round" onClick={() => {
															setIsDirty(true);
															setMeasurementList(prevList =>
																prevList.map(m =>
																	m.id === pid.id ? { ...m, verificationStatus: VERIFICATION_STATUS[pid.verificationStatus].nextStatus } : m
																)
															);
														}}
															icon={VERIFICATION_STATUS[pid.verificationStatus].icon}>
															{pid.description}
														</Button>
													</Tooltip>
												)
											}))
										}
									</Space>

								</PanelBody>
							</Panel>}
						</Col>
					</Row>
					<Row className={"formFooter"}>
						<Col xs={{ span: 24 }}>
							<Space>
								<Button
									type="primary"
									size="large"
									htmlType="submit"
									disabled={!isDirty || isSubmitting}
								>
									{t('Common.SaveButton')}
								</Button>
								<Button size="large" onClick={() => {
									if (!!selectedVehicleModel) {
										setSelectedVehicleModel(undefined);
									}
									else {
										history.goBack();
									}
								}}>
									{t('Common.CancelButton')}
								</Button>
							</Space>
						</Col>
					</Row>
				</Form>
			</Spin>
			<VehicleModelDocumentUploadModal
				documentList={finalDocumentList}
				entityType={ENTITY_TYPE_VEHICLE_MODEL}
				isModalOpen={isDocumentUploadModalVisible}
				onComplete={(e) => {
					setFinalDocumentList(e);
					setIsDirty(true);
					setIsDocumentUploadModalVisible(false);
				}}
				onClose={() => {
					setIsDocumentUploadModalVisible(false);
				}} />
		</>
	)
}

export default VehicleModelGroupForm;